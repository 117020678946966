import './App.scss';
import './reset.scss';

function App() {
    return (
        <main className="main">
            <div className="header">
                <h1 className="title">Blue Whale</h1>
                <div className="header__desc">
                    <p className="text">
                        HI, I’M $BWELE!
                        <br />
                        DUDE, I’M BASICALLY A WHALE WTF DO YOU EXPECT FROM ME?
                    </p>
                    <p className="text">0% BUY/SALE TAX | 0% TEAM ALLOCATION | 100% WHALE | 100% BASED</p>
                    <p className="text">
                        Contract: <br />
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://basescan.org/token/0x57b52fe1a6e7aecf45d744388d3b2198bf9c063e"
                        >
                            0x57b52fe1a6e7aecf45d744388d3b2198bf9c063e
                        </a>
                    </p>
                    <a
                        href="https://app.uniswap.org/swap?outputCurrency=0x57b52fe1a6e7aecf45d744388d3b2198bf9c063e&chain=base"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="button">BUY $BWELE</button>
                    </a>
                    <div className="socials__wrapper">
                        <a href="https://t.me/BlueWhale_based" target="_blank" rel="noopener noreferrer">
                            <img src="tg.png" className="link_logo" />
                        </a>
                        <a href="https://twitter.com/bluewhale_base" target="_blank" rel="noopener noreferrer">
                            <img src="x.png" className="link_logo" />
                        </a>
                        <a
                            href="https://basescan.org/token/0x57b52fe1a6e7aecf45d744388d3b2198bf9c063e"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="base.png" className="link_logo" />
                        </a>
                        <a
                            href="https://dexscreener.com/base/0xf5fc02f29405e3a1feafe23edee2678d3e7b64fd"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="dexscreener.png" className="link_logo" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer">
                <img src="blue_cut.png" className="blu_img" />
            </div>
        </main>
    );
}

export default App;
